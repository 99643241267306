import React from "react";
const TableGen = ({ table }) => {
  return (
    <>
      <table style={{ width: "100%" }}>
        <thead>
          {table.thead.map((th) => (
            <tr className={th.className}>
              {th.data.map((i) => (
                <th
                  colSpan={i.cols ? i.cols : 1}
                  rowSpan={i.rows ? i.rows : 1}
                  style={i.styles ? i.styles : null}
                >
                  {i.value ? i.value : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.tbody.map((th) => (
            <>
              {th.br ? <br /> : null}
              <tr className={th.className}>
                {th.data.map((i) => (
                  <td
                    colSpan={i.cols ? i.cols : 1}
                    rowSpan={i.rows ? i.rows : 1}
                    style={i.styles ? i.styles : null}
                  >
                    {i.value ? i.value : ""}
                  </td>
                ))}
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default TableGen;
