import React, { useState } from "react";
import "./Tulov.css";
import { RiPrinterFill, RiRefreshLine } from "react-icons/ri";
import moment from "moment";
import { Button, DatePicker, message, Select, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import fetchApi from "../Functions/fetchApi.";
import { PrinterOutlined, ReloadOutlined } from "@ant-design/icons";
import NewComponent from "../Functions/NewComponent";
import { useHistory } from "react-router";
import PhoneInput from "react-phone-input-2";
import numeral from "numeral";

const Tulov = ({ mijozlar, showModal, tolovMalumotlar, setMalumotlar }) => {
  const [progText, progTextOzgartir] = useState("Маълумот жўнатилмоқда...");

  const history = useHistory();

  function OnMijozChange(value) {
    setMalumotlar({
      ...tolovMalumotlar,
      mijozId: value,
      mijozNomi: mijozlar?.filter((d) => d?.Id == value)[0].nomi,
    });
  }

  function ClearAllData() {
    setMalumotlar({
      mijozId: "",
      loading: "",
      mijozQarzi: null,
      naqd: null,
      online: null,
      xumo: null,
      click: null,
      izox: null,
      telefon: null,
      tolovTuri: "1",
      sana1: new Date(),
      sana5: null,
      docId: 0,
      mijozNomi: "",
    });
  }

  const OnClose = (e) => {
    ClearAllData();
    showModal(false);
  };

  const onClick = (e) => {
    if (tolovMalumotlar.mijozId) {
      OnClick2(e);
    } else {
      message.error({ content: "Мижоз танланг!" });
    }
  };

  const OnClick2 = async (e) => {
    setMalumotlar({
      ...tolovMalumotlar,
      loading: true,
    });

    const formData = new FormData();
    formData.append(
      "user_id",
      JSON.parse(window.localStorage.getItem("user")).Id
    );
    formData.append("sorov_izox", `4#${tolovMalumotlar.mijozId}`);

    const resp = await fetchApi("/in_savl.php", "POST", formData);
    console.log(resp);
    if (resp && resp.status === 200) {
      progTextOzgartir("Маълумотлар Сервердан олинмоқда...");
      const formData2 = new FormData();
      formData2.append("sorov_id", resp.data);
      console.log("savol id = " + resp.data);
      const inter = setInterval(async () => {
        const resp2 = await fetchApi("/gt_javb.php", "POST", formData2);
        if (resp2.status === 200) {
          console.log(resp2.data);
          if (!isNaN(resp2.data) || resp2.data.trim() != "not") {
            setMalumotlar({
              ...tolovMalumotlar,
              loading: false,
              mijozQarzi: resp2.data,
            });
            clearInterval(inter);
          }
        }
      }, 1000);
    }
  };

  const onClickSaqlash = (e) => {
    if (!tolovMalumotlar.mijozId) {
      message.error({ content: "Мижоз танланг!" });
      return;
    }
    if (!tolovMalumotlar.tolovTuri) {
      message.error({ content: "Тўлов турини танланг!" });
      return;
    }

    if (!tolovMalumotlar.sana5) {
      if (
        tolovMalumotlar.naqd +
          tolovMalumotlar.online +
          tolovMalumotlar.xumo +
          tolovMalumotlar.click ==
        0
      ) {
        message.error({ content: "Илтимос суммани киритинг!" });
        return;
      }
    }

    OnSaqlash(e);
  };

  const OnSaqlash = async (e) => {
    // setMalumotlar({
    //   ...tolovMalumotlar,
    //   loading: true,
    // });

    const formData = new FormData();
    formData.append(
      "user_id",
      JSON.parse(window.localStorage.getItem("user")).Id
    );
    formData.append(
      "sorov_izox",
      `5#${moment(tolovMalumotlar.sana1).format("DD.MM.YYYY HH:mm:ss")}#${
        tolovMalumotlar.mijozId
      }#${tolovMalumotlar.naqd}#${tolovMalumotlar.online}#${
        tolovMalumotlar.xumo
      }#${tolovMalumotlar.click}#${tolovMalumotlar.tolovTuri}#${moment(
        tolovMalumotlar.sana5
      ).format("DD.MM.YYYY")}#${
        tolovMalumotlar.izox + "\nТелефон: " + tolovMalumotlar.telefon
      }`
    );

    const resp = await fetchApi("/in_savol_tolov.php", "POST", formData);
    console.log(resp);
    if (resp && resp.status === 200) {
      if (tolovMalumotlar.telefon) {
        const formData2 = new FormData();
        formData2.append("phone", tolovMalumotlar.telefon);
        formData2.append(
          "message",
          '"Best Online Servise" MChJga ' +
            tolovMalumotlar.mijozNomi
              .replace(" Kassa apparat olingan", "")
              .replace(" Kassa apparat olinmagan", "") +
            " nomidan " +
            (tolovMalumotlar.naqd * 1 +
              tolovMalumotlar.online * 1 +
              tolovMalumotlar.xumo * 1 +
              tolovMalumotlar.click * 1) +
            " so'm to`lov qabul qilindi!"
        );

        console.log(formData2);
        const resp3 = await fetchApi("/in_tolov.php", "POST", formData2);
        console.log(resp3);
        if (resp3 && resp3.status === 200) {
          progTextOzgartir("Маълумотлар Серверга сақланмоқда...");
          setMalumotlar({
            ...tolovMalumotlar,
            docId: resp.data,
          });
          showModal(false);
          history.push("/print");
        }
      } else {
        progTextOzgartir("Маълумотлар Серверга сақланмоқда...");
        setMalumotlar({
          ...tolovMalumotlar,
          docId: resp.data,
        });
        showModal(false);
        history.push("/print");
      }
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  const handleChange = (event) => {
    setMalumotlar({
      ...tolovMalumotlar,
      tolovTuri: event.target.value,
    });
  };

  return (
    <div className="container">
      <div className="mainTulov">
        <div className="mainType">
          <div className="mainbody">
            <div className="grid-1-1">
              <div style={{ display: "grid" }}>
                <label>Сана</label>
                <DatePicker
                  name="Date"
                  allowClear={false}
                  disabled
                  onChange={(d) =>
                    setMalumotlar({
                      ...tolovMalumotlar,
                      sana1: d,
                    })
                  }
                  style={{ width: "100%" }}
                  showTime={{ undefined }}
                  value={moment(tolovMalumotlar.sana1)}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label>Мижоз танланг</label>
                <Select
                  showSearch
                  placeholder="Мижоз танланг"
                  optionFilterProp="children"
                  onChange={OnMijozChange}
                  value={tolovMalumotlar.mijozId}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {mijozlar?.map((op) => (
                    <Option value={op.Id} key={op.Id}>
                      {op.nomi}
                    </Option>
                  ))}
                </Select>
              </div>
              <div style={{ display: "grid" }}>
                <label>Қарздорлиги</label>
                <label style={{ fontSize: "16px" }}>
                  {tolovMalumotlar.mijozQarzi}
                </label>
              </div>
              <div style={{ display: "grid", alignSelf: "end" }}>
                <Button
                  htmlType="submit"
                  icon={<ReloadOutlined />}
                  loading={tolovMalumotlar.loading}
                  onClick={onClick}
                ></Button>
              </div>
            </div>
            <div className="grid-1-5">
              <div>
                <label>Нақд</label>
                <input
                  type="number"
                  className="s_naqd"
                  placeholder="Нақд"
                  style={{ width: "150px", marginLeft: "10px" }}
                  value={tolovMalumotlar.naqd}
                  onInput={(e) =>
                    setMalumotlar({
                      ...tolovMalumotlar,
                      naqd: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label>Онлайн</label>
                <input
                  type="number"
                  className="s_naqd"
                  placeholder="Онлайн"
                  style={{ width: "130px", marginLeft: "10px" }}
                  value={tolovMalumotlar.online}
                  onInput={(e) =>
                    setMalumotlar({
                      ...tolovMalumotlar,
                      online: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label>Ҳумо</label>
                <input
                  type="number"
                  className="s_naqd"
                  style={{ width: "130px", marginLeft: "10px" }}
                  placeholder="Ҳумо"
                  value={tolovMalumotlar.xumo}
                  onInput={(e) =>
                    setMalumotlar({
                      ...tolovMalumotlar,
                      xumo: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label>Click</label>
                <input
                  type="number"
                  className="s_naqd"
                  style={{ width: "130px", marginLeft: "10px" }}
                  placeholder="Click"
                  value={tolovMalumotlar.click}
                  onInput={(e) =>
                    setMalumotlar({
                      ...tolovMalumotlar,
                      click: e.target.value,
                    })
                  }
                />
              </div>

              <div style={{ display: "grid" }}>
                <label>Кейинги тўлов санаси</label>
                <DatePicker
                  name="Date"
                  allowClear={true}
                  onChange={(d) =>
                    setMalumotlar({
                      ...tolovMalumotlar,
                      sana5: d,
                    })
                  }
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                  showTime={{ undefined }}
                  disabledDate={disabledDate}
                  value={
                    tolovMalumotlar.sana5 == undefined
                      ? ""
                      : moment(tolovMalumotlar.sana5)
                  }
                />
              </div>
            </div>
            <div className="grid-1">
              <div style={{ display: "grid" }}>
                <label>Телефон номер</label>
                <PhoneInput
                  country={"uz"}
                  inputStyle={{
                    width: "100%",
                    border: "1px solid #ccc",
                    padding: "4px 11px",
                    borderRadius: "var(--input-radius)",
                    backgroundColor: "var(--input-bg)",
                  }}
                  specialLabel={false}
                  disableDropdown={true}
                  countryCodeEditable={false}
                  value={tolovMalumotlar.telefon}
                  areaCodes={{
                    uz: ["+998"],
                  }}
                  masks={{ uz: "(..) ...-..-.." }}
                  prefix="+"
                  onChange={(phone) => {
                    setMalumotlar({
                      ...tolovMalumotlar,
                      telefon: phone,
                    });
                  }}
                />
              </div>
              <div style={{ display: "grid" }}>
                <label>Изоҳ</label>
                <input
                  type="text"
                  name="text"
                  className="mijoz_izoh"
                  placeholder="Изоҳ"
                  value={tolovMalumotlar.izox}
                  onInput={(e) =>
                    setMalumotlar({
                      ...tolovMalumotlar,
                      izox: e.target.value,
                    })
                  }
                />
              </div>
              <div style={{ display: "grid" }}>
                <label>Тўлов тури</label>
                <select
                  name="tulov_turi"
                  onChange={handleChange}
                  className="tulov_turi"
                >
                  <option value="1" key="1">
                    Обонент тўлов
                  </option>
                  <option value="2" key="2">
                    Кредит тўлов
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="mainbtn">
            <Button
              htmlType="submit"
              onClick={OnClose}
              style={{
                background: "var(--type-color)",
                color: "blue",
                width: "100px",
              }}
              loading={tolovMalumotlar.loading}
            >
              Ортга
            </Button>
            <Button
              htmlType="submit"
              onClick={onClickSaqlash}
              style={{
                background: "var(--main-color)",
                color: "white",
                width: "100px",
              }}
              loading={tolovMalumotlar.loading}
            >
              Сақлаш
            </Button>
            {/* <Button
              htmlType="submit"
              icon={<PrinterOutlined />}
              loading={tolovMalumotlar.loading}
              onClick={onClickSaqlash}
            > */}
            {/* <RiPrinterFill
                style={{ fontSize: "25px", cursor: "pointer" }}
              ></RiPrinterFill> */}
            {/* </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tulov;
