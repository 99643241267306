import React, { useState } from "react";
import Sverka from "./AktSverka.css";
import { Button, DatePicker, message, Spin } from "antd";
import moment from "moment";
import fetchApi from "../Functions/fetchApi.";
import ReactHtmlParser from "react-html-parser";
import { Select } from "antd";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { Option } from "antd/lib/mentions";

const AktSverka = ({
  mijozlar,
  regionlar,
  htmlOzgartir,
  progTextOzgartir,
  setLoading,
  loading,
}) => {
  const [mijozId, setMijoz] = useState("");
  const [sana1, dateOzgartir] = useState(new Date());
  const [sana2, date2Ozgartir] = useState(new Date());
  const dateFormat = "DD.MM.YYYY";
  function OnMijozChange(value) {
    setMijoz(value);
  }

  const OnClick = (e) => {
    if (mijozId && mijozId != "") {
      htmlOzgartir("");
      OnClick2(e);
    } else {
      message.error({ content: "Mijoz tanlang" });
    }
  };

  const OnClick2 = async (e) => {
    setLoading(true);

    const formData = new FormData();
    formData.append(
      "user_id",
      JSON.parse(window.localStorage.getItem("user")).Id
    );
    formData.append(
      "sorov_izox",
      `2#${moment(sana1).format("DD.MM.YYYY")}#${moment(sana2).format(
        "DD.MM.YYYY"
      )}#${mijozId}`
    );

    const resp = await fetchApi("/in_savl.php", "POST", formData);
    console.log(resp);
    if (resp && resp.status === 200) {
      progTextOzgartir("Маълумотлар Сервердан олинмоқда...");
      const formData2 = new FormData();
      formData2.append("sorov_id", resp.data);

      const inter = setInterval(async () => {
        const resp2 = await fetchApi("/gt_javb.php", "POST", formData2);
        if (resp2.status === 200) {
          if (resp2.data.length > 100) {
            htmlOzgartir(resp2.data);
            clearInterval(inter);
            setLoading(false);
          }
        }
      }, 1000);
    }
  };

  return (
    <div>
      <div className="mainAkt_components">
        <div className="Aktcomponents_input">
          <div className="component_date">
            <DatePicker
              name="Date1"
              allowClear={false}
              onChange={(d) => dateOzgartir(d)}
              style={{ width: "100%" }}
              value={moment(sana1)}
            />
            <DatePicker
              name="Date2"
              allowClear={false}
              onChange={(d) => date2Ozgartir(d)}
              style={{ width: "100%" }}
              value={moment(sana2)}
            />
            <Select
              showSearch
              placeholder="Mijoz tanlang"
              style={{ minWidth: 300 }}
              className="Akt_Mijoz"
              optionFilterProp="children"
              onChange={OnMijozChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {mijozlar?.map((op) => (
                <Option value={op.Id} key={op.Id}>
                  {op.nomi}
                </Option>
              ))}
            </Select>
            <Button
              htmlType="submit"
              loading={loading}
              onClick={OnClick}
              className="Input"
            >
              Янгилаш
            </Button>

            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              table="table-to-xls"
              filename={"Акт сверка " + moment().format("DD.MM.YYYY HH:mm:ss")}
              sheet="Акт сверка"
              className="Input"
              buttonText="Excelга олиш"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AktSverka;
