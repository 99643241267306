import React, { useState } from "react";
import LogoImg from "../icon/logotip.png";
import { FiLogOut } from "react-icons/fi";
import "./Header.css";
import LogOut from "./LogOut";
import { Button, Modal, TimePicker } from "antd";
import Tulov from "../Tulov_oynasi/Tulov";

const Header = (props) => {
  const [ismodal, showModal] = useState(false);

  const logOut = (e) => {
    props.setLog(!props.log);
  };

  const OnTolovCancel = (e) => {
    showModal(false);
  };

  const OnTolovClick = (e) => {
    props.htmlOzgartir("");
    showModal(true);
  };

  const OnTolovOK = (e) => {
    showModal(true);
  };

  return (
    <div className="MainHeader">
      <div className="mainLogotip">
        <img src={LogoImg} alt="brend" className="brend" />
      </div>
      <div className="mainMenu">
        <nav className="MainNavigation">
          <ul className="MainNavigationItems">
            <li
              className={
                window.location.pathname == "/MijozQarzdorligi"
                  ? "MainNavigationItem1 MainNavigationItem1Active"
                  : "MainNavigationItem1"
              }
            >
              <a href="/MijozQarzdorligi">Мижоз қарздорлиги</a>
            </li>
            <li
              className={
                window.location.pathname == "/AktSverka"
                  ? "MainNavigationItem1 MainNavigationItem1Active"
                  : "MainNavigationItem1"
              }
            >
              <a href="/AktSverka">Акт сверка</a>
            </li>
          </ul>
        </nav>
      </div>
      <div></div>
      <div className="mainFunction" style={{ position: "initial" }}>
        <Button
          htmlType="submit"
          onClick={OnTolovClick}
          style={{ marginRight: "15px" }}
        >
          Тўлов
        </Button>
        <div style={{ color: "white", display: "flex", alignItems: "center" }}>
          {JSON.parse(window.localStorage.getItem("user")).fio} (
          {JSON.parse(window.localStorage.getItem("user")).nomi}){" "}
        </div>
        <FiLogOut
          className="icon"
          style={{ width: "30px", height: "30px", color: "red" }}
          onClick={() => logOut()}
        ></FiLogOut>
      </div>

      <Modal
        centered
        footer={null}
        title="Тўлов ойна"
        onCancel={OnTolovCancel}
        onOk={OnTolovOK}
        visible={ismodal}
        maskClosable={false}
        closable={false}
        width={910}
      >
        <Tulov
          mijozlar={props.mijozlar}
          showModal={showModal}
          tolovMalumotlar={props.tolovMalumotlar}
          setMalumotlar={props.setMalumotlar}
        />
      </Modal>
    </div>
  );
};

export default Header;
