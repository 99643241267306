import React, { useState } from "react";
import "./Login.css";
import Logo from "../Logo/Logo.js";
import { Button, message } from "antd";
import fetchApi from "../Functions/fetchApi.";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [login, loginOzgartir] = useState("");
  const [parol, parolOzgartir] = useState("");

  const OnClick = (e) => {
    console.log("onclick");
    OnClick2(e);
  };

  const OnClick2 = async (e) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("login", login);
    formData.append("parol", parol);

    const response = await fetchApi("/get_fillial.php", "POST", formData);
    console.log(response);
    if (response && response.data.length > 0) {
      window.localStorage.setItem("user", JSON.stringify(response.data[0]));

      window.location.href = "/MijozQarzdorligi";
    } else {
      message.error("Login yoki parol xato!");
      console.log("Login yoki parol xato!");
    }
    setLoading(false);
  };

  return (
    <div className="container_Login">
      <div className="mainLogin">
        <div className="gridLogin">
          <Logo></Logo>
        </div>
        <div className="loginInput">
          <input
            type="text"
            className="Login"
            name="login"
            placeholder="Логин"
            value={login}
            onInput={(e) => loginOzgartir(e.target.value)}
          />
          <input
            type="text"
            className="Login"
            name="parol"
            placeholder="Парол"
            value={parol}
            onInput={(e) => parolOzgartir(e.target.value)}
          />
        </div>
        <div className="loginBtn">
          <Button
            htmlType="submit"
            loading={loading}
            onClick={OnClick}
            className="btnLogin"
            style={{ backgroundColor: "var( --main-color )" }}
          >
            Кириш
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
