import { useEffect, useState } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import AktSverka from "./Akt_sverka/AktSverka";
import "./App.css";
import fetchApi from "./Functions/fetchApi.";
import Header from "./Header/Header";
import LogOut from "./Header/LogOut";
import Login from "./Login/Login";
import MijozQarzdorligi from "./MIjoz_qarzdorligi/MijozQarzdorligi";
import MijozQoldigi from "./Mijoz_qoldigi/MijozQoldigi";
import ReactHtmlParser from "react-html-parser";

import "antd/dist/antd.css";
import { Spin } from "antd";
import Print from "./Tulov_oynasi/Print";

function App() {
  const [user, setUser] = useState();
  const [log, setLog] = useState(false);

  const [htmlMalumot, htmlOzgartir] = useState("");
  const [progText, progTextOzgartir] = useState("Маълумот жўнатилмоқда...");
  const [loading, setLoading] = useState(false);

  const [tolovMalumotlar, setTolovMalumotlar] = useState({
    mijozId: "",
    loading: "",
    mijozQarzi: null,
    naqd: null,
    online: null,
    xumo: null,
    click: null,
    izox: null,
    telefon: "+998",
    tolovTuri: "1",
    sana1: new Date(),
    sana5: undefined,
    docId: 0,
    mijozNomi: "",
  });

  const [malumotlar, setMalumotlar] = useState({
    region: [],
    mijoz: [],
  });

  useEffect(() => {
    setUser(JSON.parse(window.localStorage.getItem("user")));
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const respRegion = await fetchApi("/get_region.php", "GET");

    const respMijoz = await fetchApi("/get_mijozlar.php", "GET");

    if (respRegion && respRegion.status === 200) {
      setMalumotlar({
        region: respRegion.data,
        mijoz: respMijoz.data,
      });
    }
  };

  useEffect(() => {
    const table = document.querySelector("table");
    if (table) {
      table.setAttribute("id", "table-to-xls");
    }
  }, [htmlMalumot]);

  let main = null;

  if (user) {
    main = (
      <div className="App">
        <div style={{ position: "fixed", width: "100%" }}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/print">
                <Print
                  tolovMalumotlar={tolovMalumotlar}
                  setTolovMalumotlar={setTolovMalumotlar}
                />
              </Route>
              <Route path="/">
                <Header
                  log={log}
                  setLog={setLog}
                  mijozlar={malumotlar.mijoz}
                  tolovMalumotlar={tolovMalumotlar}
                  setMalumotlar={setTolovMalumotlar}
                  htmlOzgartir={htmlOzgartir}
                />
                <Route path="/AktSverka">
                  <AktSverka
                    mijozlar={malumotlar.mijoz}
                    regionlar={malumotlar.region}
                    htmlOzgartir={htmlOzgartir}
                    progTextOzgartir={progTextOzgartir}
                    setLoading={setLoading}
                    loading={loading}
                  />
                </Route>
                <Route path="/MijozQarzdorligi">
                  <MijozQarzdorligi
                    mijozlar={malumotlar.mijoz}
                    regionlar={malumotlar.region}
                    htmlOzgartir={htmlOzgartir}
                    progTextOzgartir={progTextOzgartir}
                    setLoading={setLoading}
                    loading={loading}
                  />
                </Route>
              </Route>
            </Switch>
          </BrowserRouter>
        </div>
        {log ? <LogOut setlogOut={setLog} /> : ""}
        {htmlMalumot || loading ? (
          <div
            className="mijozbody"
            style={{
              display: "flex",
              justifyContent: "center",
              boxSizing: "border-box",
            }}
          >
            <div className="mijozbody-content" style={{ marginTop: "128px" }}>
              {loading ? (
                <Spin
                  spinning={loading}
                  tip={progText}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    boxSizing: "border-box",
                    gridGap: "2%",
                  }}
                ></Spin>
              ) : (
                <></>
              )}
            </div>
            <div style={{ marginTop: "128px" }}>
              {ReactHtmlParser(htmlMalumot)}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  } else {
    main = <Login />;
  }
  return main;
}

export default App;
