import React, { useEffect } from "react";
import TableGen from "../Functions/TableGen";
import moment from "moment";
import { useHistory } from "react-router";

const Print = ({ tolovMalumotlar, setTolovMalumotlar }) => {
  const history = useHistory();

  const table = {
    thead: [
      {
        data: [
          {
            value: "Тулов квитансияси",
            cols: 2,
            styles: {
              borderLeft: "1px solid black",
              borderTop: "1px solid black",
              borderRight: "1px solid black",
              fontWeight: "700",
            },
          },
          {
            value: "Карздорлик",
            cols: 2,
            styles: {
              fontWeight: "700",
              borderLeft: "1px solid black",
              borderTop: "1px solid black",
              borderRight: "1px solid black",
              textAlign: "center",
            },
          },
        ],
      },
    ],
    tbody: [
      {
        data: [
          {
            value: `№ ${tolovMalumotlar.docId} Сана: ${moment(
              tolovMalumotlar.sana1
            ).format("DD.MM.YYYY")}`,
            cols: 2,
            styles: {
              fontWeight: "600",
              borderLeft: "1px solid black",
              textAlign: "center",
            },
          },
          {
            value: `${tolovMalumotlar.mijozQarzi}`,
            cols: 2,
            styles: {
              borderLeft: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              textAlign: "center",
            },
          },
        ],
      },
      {
        data: [
          {
            value: `Тўловчи:`,
            styles: {
              fontWeight: "500",
              borderLeft: "1px solid black",
              textAlign: "right",
              width: "80px",
            },
          },
          {
            value: `${tolovMalumotlar.mijozNomi}`,
            styles: {
              borderRight: "1px solid black",
              paddingLeft: "15px",
            },
          },
          {
            value: `Тўлов`,
            cols: 2,
            styles: {
              borderLeft: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              textAlign: "center",
            },
          },
        ],
      },
      {
        data: [
          {
            value: `Олувчи:`,
            styles: {
              fontWeight: "500",
              borderLeft: "1px solid black",
              borderBottom: "1px solid black",
              textAlign: "right",
              width: "80px",
              lineHeight: "1",
            },
          },
          {
            value: `"BEST ONLINE SERVICE" MCHJ`,
            styles: {
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              paddingLeft: "15px",
            },
          },
          {
            value: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Накд</span>
                {tolovMalumotlar.naqd}
              </div>
            ),
            styles: {
              borderLeft: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
            },
          },
          {
            value: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Пластик</span>
                {tolovMalumotlar.online * 1 +
                  tolovMalumotlar.xumo * 1 +
                  tolovMalumotlar.click * 1}
              </div>
            ),
            styles: {
              borderLeft: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
            },
          },
        ],
      },
      // {
      //   data: [
      //     {
      //       value: `Сумма сўз б-н:`,
      //       styles: {
      //         fontWeight: "500",
      //         borderLeft: "1px solid black",
      //         borderBottom: "1px solid black",
      //         textAlign: "right",
      //         width: "80px",
      //         lineHeight: "1",
      //       },
      //     },
      //     {
      //       value: `Бир миллион `,
      //       cols: 3,
      //       styles: {
      //         borderBottom: "1px solid black",
      //         borderRight: "1px solid black",
      //         paddingLeft: "15px",
      //       },
      //     },
      //   ],
      // },
      //   {
      //     data: [
      //       {
      //         value: `Тўлов мақсади:`,
      //         styles: {
      //           fontWeight: "500",
      //           borderLeft: "1px solid black",
      //           borderBottom: "1px solid black",
      //           textAlign: "right",
      //           width: "80px",
      //           lineHeight: "1",
      //         },
      //       },
      //       {
      //         value: `-санадаги 0-рақамли шартномага асосан тулов пули.`,
      //         cols: 3,
      //         styles: {
      //           borderBottom: "1px solid black",
      //           borderRight: "1px solid black",
      //           paddingLeft: "15px",
      //         },
      //       },
      //     ],
      //   },
      {
        data: [
          {
            value: `Қолдиқ сумма:`,
            cols: 2,
            styles: {
              fontWeight: "500",
              borderLeft: "1px solid black",
              borderBottom: "1px solid black",
              textAlign: "right",
              width: "80px",
              lineHeight: "1",
            },
          },
          // {
          //   value: ``,
          //   cols: 1,
          //   styles: {
          //     borderBottom: "1px solid black",
          //     paddingLeft: "15px",
          //   },
          // },
          {
            value: `${
              tolovMalumotlar.mijozQarzi
                ? tolovMalumotlar.mijozQarzi * 1 -
                  (tolovMalumotlar.online * 1 +
                    tolovMalumotlar.xumo * 1 +
                    tolovMalumotlar.click * 1 +
                    tolovMalumotlar.naqd * 1)
                : "Қарздорлик киритилмаган"
            }`,
            cols: 2,
            styles: {
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              textAlign: "center",
            },
          },
        ],
      },
      {
        data: [
          {
            value: `Кассир:`,
            styles: {
              fontWeight: "500",
              borderLeft: "1px solid black",
              borderBottom: "1px solid black",
              textAlign: "right",
              width: "80px",
              lineHeight: "1",
              height: "50px",
            },
          },
          {
            value: `${
              JSON.parse(window.localStorage.getItem("user")).fio
            }. . . . . . . . . . . . Имзо:`,
            cols: 1,
            styles: {
              borderBottom: "1px solid black",
              textAlign: "center",
            },
          },
          {
            value: `МУ`,
            cols: 2,
            styles: {
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              textAlign: "center",
            },
          },
        ],
      },
    ],
  };

  console.log(tolovMalumotlar);

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 20);

    window.onafterprint = function (event) {
      setTolovMalumotlar({
        mijozId: "",
        loading: "",
        mijozQarzi: null,
        naqd: null,
        online: null,
        xumo: null,
        click: null,
        izox: null,
        tolovTuri: "1",
        sana1: new Date(),
        docId: 0,
        mijozNomi: "",
      });
      history.goBack();
    };
  }, []);

  return (
    <div style={{ margin: "10px", width: "700px" }}>
      <TableGen table={table} />
      <hr />
      <TableGen table={table} />
      <hr />
    </div>
  );
};

export default Print;
