import React from "react";
import LogoImg from "../icon/logo.png";

const Logo = () => {
  return (
    <div className="mainLogo">
      <img
        src={LogoImg}
        alt="brend"
        className="brend"
        style={{ height: "46px", width: "139px" }}
      />
    </div>
  );
};

export default Logo;
