import { Button } from "antd";
import React from "react";
import "./Header.css";

const LogOut = (props) => {
  const logOut = (e) => {
    localStorage.removeItem("user");
    window.location.reload();
  };
  const cancel = (e) => {};
  return (
    <div className="containerLog">
      <div className="mainLogout">
        <div className="Textlog">
          <span> Дастурдан чиқиш !</span>
        </div>
        <div className="logOutBtn">
          <Button
            htmlType="submit"
            onClick={() => logOut()}
            className="btnLogout"
          >
            Чиқиш
          </Button>
          <Button
            htmlType="submit"
            onClick={() => props.setlogOut(false)}
            className="btnLogout"
            // style={{ backgroundColor: "var( --main-color )" }}
          >
            Бекор қилиш
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LogOut;
