import React, { useState } from "react";
import MZ_qarzdorligi from "./MijozQarzdorligi.css";
import { Button, DatePicker, message, Spin } from "antd";
import moment from "moment";
import fetchApi from "../Functions/fetchApi.";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Select } from "antd";

import { Option } from "antd/lib/mentions";

const MijozQarzdorligi = ({
  mijozlar,
  regionlar,
  htmlOzgartir,
  progTextOzgartir,
  setLoading,
  loading,
}) => {
  const [mijozId, setMijoz] = useState("");
  const [regionId, setRegion] = useState("");
  const [sana1, dateOzgartir] = useState(new Date());

  function OnMijozChange(value) {
    setMijoz(value);
  }
  function OnRegionChange(value) {
    setRegion(value);
  }

  const OnClick = (e) => {
    htmlOzgartir("");
    OnClick2(e);
  };

  const OnClick2 = async (e) => {
    if (regionId == undefined || regionId == "") {
      message.error({ content: "Илтимос регион танланг!" });
    } else {
      setLoading(true);
      console.log(regionId);
      const formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(window.localStorage.getItem("user")).Id
      );
      formData.append(
        "sorov_izox",
        `3#${moment(sana1).format("DD.MM.YYYY")}#${mijozId}#${regionId}`
      );

      const resp = await fetchApi("/in_savl.php", "POST", formData);
      if (resp && resp.status === 200) {
        progTextOzgartir("Маълумотлар Сервердан олинмоқда...");
        const formData2 = new FormData();
        formData2.append("sorov_id", resp.data);

        const inter = setInterval(async () => {
          const resp2 = await fetchApi("/gt_javb.php", "POST", formData2);
          if (resp2?.status === 200) {
            if (resp2?.data.length > 100) {
              htmlOzgartir(resp2.data);
              clearInterval(inter);
              setLoading(false);
            }
          }
        }, 1000);
      }
    }
  };

  return (
    <div className="mijoz_container">
      <div className="Mmain_components">
        <div className="Mcomponents_input">
          <div className="Mcomponent_date">
            <DatePicker
              allowClear={false}
              onChange={(d) => dateOzgartir(d)}
              style={{ width: "100%" }}
              value={moment(sana1)}
            />
            <Select
              showSearch
              placeholder="Regionni tanlang"
              allowClear={true}
              optionFilterProp="children"
              onChange={OnRegionChange}
              style={{ minWidth: 100 }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {regionlar?.map((op) => (
                <Option value={op.Id} key={op.Id}>
                  {op.nomi}
                </Option>
              ))}
            </Select>
            <Select
              showSearch
              allowClear={true}
              placeholder="Mijoz tanlang"
              style={{ minWidth: 300 }}
              optionFilterProp="children"
              onChange={OnMijozChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {mijozlar
                ?.filter((d) => d?.region_id == regionId)
                .map((op) => (
                  <Option value={op.Id} key={op.Id}>
                    {op.nomi}
                  </Option>
                ))}
            </Select>
            <Button
              htmlType="submit"
              loading={loading}
              onClick={OnClick}
              className="Input"
            >
              Янгилаш
            </Button>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              table="table-to-xls"
              filename={
                "Мижоз қарздорлиги " + moment().format("DD.MM.YYYY HH:mm:ss")
              }
              sheet="Мижоз қарздорлиги"
              className="Input"
              buttonText="Excelга олиш"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MijozQarzdorligi;
